import React, { useState } from 'react';
import './PasswordModal.css'; // モーダルのスタイルシート

function PasswordModal({ isOpen, onClose, onPasswordSubmit }) {
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onPasswordSubmit(password);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
        <div className="modal">
            <h2>合言葉は「〇」</h2>
            <h3>（さいばーひっぴー#0 ただいま孤独 巻末ページ参照）</h3>
            <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="合言葉"
                autoFocus
            />
            <button type="submit">送信</button>
            <button onClick={onClose}>キャンセル</button>
            </form>
        </div>
        </div>
    );
}

export default PasswordModal;
