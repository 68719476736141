import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Archive from './Archive'; // 雑誌のアーカイブ用のコンポーネント
import EventPage from './EventPage'; // イベントページコンポーネントのインポート
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/archive" element={<Archive />} />
      <Route path="/events/1" element={<EventPage />} /> {/* イベントページへのルートを追加 */}
    </Routes>
  </Router>
);
