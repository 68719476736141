import React, { useState } from 'react';
import './App.css';
import PasswordModal from './PasswordModal';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [showEvents, setShowEvents] = useState(false); // イベントサブメニュー用

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    window.dataLayer.push({
      'event': 'menu_toggle',
      'isOpen': !isOpen
    });
  };

  const closePopup = () => {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.add('hidden'); // フェードアウト開始
      setTimeout(() => setShowPopup(false), 500); // 0.5秒後にポップアップを完全に非表示
    }
  };

  const toggleEvents = () => { // イベントサブメニューの開閉
    setShowEvents(!showEvents);
  };

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const handleDiscordLinkClick = (e) => {
    e.preventDefault();
    setIsPasswordModalOpen(true);
  };

  const handlePasswordSubmit = (password) => {
    if (password === "愛") {
      window.location.href = "https://discord.gg/FSdMugpvfh";
    } else {
      alert("ぜひ雑誌買って確認してみてね！");
    }
    setIsPasswordModalOpen(false);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  return (
    <div className="App">
      {showPopup && (
        <div className="popup" onClick={closePopup}>
          <img src="/background_org-logo.webp" alt="Welcome" className="popup-image" />
        </div>
      )}
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={isOpen ? "menu-content open" : "menu-content"}>
        <a onClick={handleDiscordLinkClick} className="menu-link">でぃすこーど</a>
        <PasswordModal
          isOpen={isPasswordModalOpen}
          onClose={closePasswordModal}
          onPasswordSubmit={handlePasswordSubmit}
        />
        <a href="https://twitter.com/cyberhippie_mag" className="menu-link" target="_blank" rel="noopener noreferrer">えっくす</a>
        <a href="https://instagram.com/CyberHippie_mag" className="menu-link" target="_blank" rel="noopener noreferrer">いんすたぐらむ</a>
        <a className={`menu-link event-toggle ${showEvents ? 'open' : ''}`} onClick={toggleEvents}>いべんと</a>
        {showEvents && (
          <div className="sub-menu">
            <a href="/events/1" className="menu-link">雑誌0号刊行記念イベント</a>
          </div>
        )}
      </div>
      <div className="background-image">
        {/* 背景画像はここに表示される */}
      </div>
    </div>
  );
}

export default App;
