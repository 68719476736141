import React from 'react';
import './EventPage.css'; // カスタム CSS ファイル
import './App.css';
import './EventPage.css';


function EventPage() {
  
  return (
    <div className="event-page">
      <img src="/event_1.png" alt="Event" className="full-screen-image" />
    </div>
  );
}

export default EventPage;
